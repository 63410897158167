<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="get involved" />
        <main>
            <section class="green-banner" v-if="this.pageData.attributes.field_description_w_summary.summary">
                <div class="container"> <p> {{ this.pageData.attributes.field_description_w_summary.summary }} </p> </div>
            </section>
            <section class="main-content">
                <div class="container">
                    <p v-html="this.pageData.attributes.field_description_w_summary.value">
                    </p>
                    <div class="attachments-container">
                        <attachments v-if="pageData" :attachmentData="pageData"></attachments>
                    </div>
                    <keep-alive v-if="this.pageData.field_ref_ui_text.length">
                        <p v-html="this.pageData.field_ref_ui_text[0].attributes.field_description_w_summary.value"></p>
                    </keep-alive>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import Attachments from '../components/Attachments.vue'
import { fetchSinglePage } from '../libs/drupalClient'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
    export default {
        name: 'privacy-notice',
        components: {
            HeaderIntroImage,
            Attachments
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "003c3f61-5561-4439-a659-723830a3b7f8"
            }
        },
        mounted() {
            fetchSinglePage(this.currentPageID, {
                include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text','field_attachments']
            })
            .then(res => {
                this.pageData = res
            })
        }
    }
</script>

<style lang="scss" scoped>
</style>